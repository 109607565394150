
import {defineComponent} from "vue";
import {Image, Space} from 'ant-design-vue';

export default defineComponent({
  name: "TeacherInformation",
  components:{
    AImage: Image,
    ASpace: Space,
  },
  setup(){
    const userInfo = JSON.parse(<string>localStorage.getItem('user'))
    return{
      userInfo,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
