
import TeacherInformation from "@/components/system/TeacherInformation.vue";
import LogoutIcon from "@/components/system/LogoutIcon.vue";
import {defineComponent} from "vue";
import {useRouter} from "vue-router";
import TeacherInformation2 from "@/components/system/TeacherInformation2.vue";
export default defineComponent({
  name: "TimetableIndex",
  components: {TeacherInformation2, LogoutIcon, TeacherInformation},
  setup(){
    const router = useRouter();
    const onRouterTo = (name: string) => {
      router.push({
        name: name,
      })
    }
    return {
      onRouterTo,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
